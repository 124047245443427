import React from "react";
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md";
import { RiInstagramLine } from "react-icons/ri";
import { useRef } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_7bim0ld",
      "template_86n2gxb",
      form.current,
      "1Tdp-4h8aPXMsh03z"
    );
    e.target.reset();
  };
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>fz.farhane109@gmail.com</h5>
            <a href="mailto:fz.farhane109@gmail.com" target="_blank">
              Email Me
            </a>
          </article>
          <article className="contact__option">
            <RiInstagramLine className="contact__option-icon" />
            <h4>Instagram</h4>
            <h5>DM Me</h5>
            <a href="https://www.instagram.com/nosis_atlas/" target="_blank">
              DM Me
            </a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            cols="30"
            rows="7"
            placeholder="Your Message"
            required
          ></textarea>
          <button className="btn btn-primary" type="submit">
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
