import React from "react";
import "./about.css";
import ME from "../../assets/me-about.webp";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-img">
            <img src={ME} alt="" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Studying</h5>
              <small>2+ Years</small>
            </article>

            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Volunteer work</h5>
              <small>3+ projects</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>15+ projects</small>
            </article>
          </div>

          <p>
            I'm Fatima Zahra Farhane, a junior web developer passionate about
            building robust and scalable web applications using PHP, Laravel,
            HTML, CSS, JavaScript, MySQL, and integrating third-party APIs.
          </p>
          <p>
            Aside from web development, I enjoy reading latest trends and best
            practices in the field. Contact me if you have a project that could
            be a good fit for my skills. Let's work together to build something
            great!
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
