import React from "react";
import "./testimonials.css";
import AVTR1 from "../../assets/avatar5.webp";
import AVTR2 from "../../assets/avatar6.webp";
// import AVTR3 from "../../assets/avatar5.png";
// import AVTR4 from "../../assets/avatar6.png";

// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    avatar: AVTR1,
    name: "Mounia BENTALEB",
    position: "training supervisor",
    review:
      "Fatima Zahra effectively communicates her ideas and accomplishments in a clear and concise manner. What sets Fatima Zahra apart is her commitment to continuous learning and improvement. I have no doubt that she will continue to achieve great things in her future endeavors.",
  },
  {
    avatar: AVTR2,
    name: "Mohamed EL AISSAOUI",
    position: "training supervisor",
    review:
      "Sa rapidité d'apprentissage, sa créativité inépuisable et son sens de l'innovation m'ont laissé stupéfait au cours de notre collaboration. Elle est extrêmement disciplinée et prête à relever les défis les plus ambitieux avec une rigueur absolue. Je suis convaincu qu'elle est destinée à un avenir brillant dans le domaine du développement digital.",
  },
  {
    avatar: AVTR2,
    name: "Amine MOURID",
    position: "mentor",
    review:
      "I highly recommend Fatima Zahra I found her a highly skilled and dedicated professional.",
  },
  {
    avatar: AVTR2,
    name: "Evariste KABORE",
    position: "mentor",
    review:
      "Fatima est une personne passionnée du code, travailleuse et concentrée. assidue dans tout ce qu'elle fait, elle arrive à mener à bien les différents projets sur lesquels elle travaille. je vous recommande son profil car je suis persuadé qu'elle sera d'un apport considérable dans votre équipe.",
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Testimonials from supervisors</h5>
      <h2>Recommendations</h2>
      <Swiper
        className="container testimonials__container" // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data.map(({ avatar, name, position, review }, index) => {
          return (
            <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar} alt="Avatar" />
              </div>
              <h5 className="client__name">{name}</h5>
              <h5 className="light__text">{position}</h5>
              <small className="client__review">{review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
