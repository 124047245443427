import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/propertymanager.webp";
import IMG2 from "../../assets/wecanmorocco.webp";
import IMG3 from "../../assets/portfolio1.webp";
import IMG4 from "../../assets/cloneinstagram.webp";
import IMG5 from "../../assets/federationgauche.webp";
import IMG6 from "../../assets/Disna.webp";
import { FaPhp } from "react-icons/fa";
import { FaLaravel } from "react-icons/fa";
// import { FaReact } from "react-icons/fa";
import { FaHtml5 } from "react-icons/fa";
import { FaCss3 } from "react-icons/fa";
import { FaBootstrap } from "react-icons/fa";

const data = [
  {
    id: 1,
    img: IMG1,
    title: "Property Manager",
    desc: "Property Manager, your go-to website for managing your house estates with ease.",
    github: "https://github.com/NosisAtlas/property-manager-v2",
    demo: "https://github.com",
    stacks: (
      <>
        <FaLaravel /> <FaHtml5 /> <FaBootstrap />
      </>
    ),
  },
  {
    id: 2,
    img: IMG2,
    title: "Wecanmorocco",
    desc: "A newly founded organization dedicated to providing English and leadership classes through American football. ",
    github: "https://github.com/NosisAtlas/wecanmorocco",
    demo: "https://wecanmorocco.com",
    stacks: (
      <>
        <FaHtml5 /> <FaCss3 /> <FaBootstrap /> <FaPhp />
      </>
    ),
  },
  {
    id: 3,
    img: IMG3,
    title: "Sensei",
    desc: "Sensei, a sports blog website dedicated to bringing you the latest news, analysis, and insights on all things sports.",
    github: "https://github.com/NosisAtlas/Sensei",
    demo: "https://github.com",
    stacks: (
      <>
        <FaHtml5 /> <FaCss3 /> <FaPhp />
      </>
    ),
  },
  {
    id: 4,
    img: IMG4,
    title: "Clone Instagram",
    desc: "InstaClone, a social media platform designed for sharing your life with friends and followers around the world.",
    github: "https://github.com/NosisAtlas/Clone-Instagram",
    demo: "https://github.com",
    stacks: (
      <>
        <FaHtml5 /> <FaCss3 /> <FaLaravel />
      </>
    ),
  },
  {
    id: 5,
    img: IMG5,
    title: "Federation Gauche",
    desc: "Omar Bendjelloun for Alliance of the left federation Welcome to the official website of Omar Bendjelloun, running for Alliance of the left federation in 2021.",
    github: "https://github.com/NosisAtlas/Federationgauche",
    demo: "https://github.com",
    stacks: (
      <>
        <FaHtml5 /> <FaCss3 /> <FaBootstrap />
      </>
    ),
  },
  {
    id: 6,
    img: IMG6,
    title: "Disna",
    desc: "Disna website was created as part of an IT hackathon, with the aim of providing a user-friendly and streamlined online shopping experience for customers.",
    github: "https://github.com/NosisAtlas/Disna",
    demo: "https://github.com",
    stacks: (
      <>
        <FaHtml5 /> <FaCss3 /> <FaBootstrap />
      </>
    ),
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, img, title, desc, stacks, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-img">
                <img src={img} alt="" />
              </div>
              <h3>{title}</h3>
              <p className="text-light">{desc}</p>
              <h5 className="stacks__style">{stacks}</h5>
              <div className="portfolio__item-cta">
                <a
                  href={github}
                  className="btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Github
                </a>
                {/* <a href={demo} className="btn btn-primary" target="_blank">
                  Live Demo
                </a> */}
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
